<template>
  <v-alert
    :elevation="1"
    colored-border
    border="bottom"
    :color="message.userColor"
    :class="{ message: true, own: own, 'pa-0': true, 'msg--caret': true }"
  >
    <v-menu offset-y offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="menu-btn" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-if="
                $root.acceso('DEV') ||
                  ($root.acceso('EDITAR_ESTUDIOS') &&
                    own &&
                    new Date().getTime() <=
                      Number(message.fecha) + 1000 * 60 * 10)
              "
              :disabled="deleting"
              @click.stop="eliminar"
            >
              <v-list-item-icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              Eliminar
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <v-card-subtitle class="py-2">
      <span
        :style="`color: ${message.userColor}`"
        class="font-weight-bold mr-2"
        >{{ message.Nombre }}</span
      >
      <v-icon small color="primary" v-if="own">mdi-account</v-icon>
      <v-icon small color="warning" v-if="comercial">mdi-account-tie</v-icon>
      {{ new Date(Number(message.fecha)).toLocaleString() }}
      <!-- <status-chip class="ml-1" x-small :value="message.tipo" v-if="message.tipo" /> -->
      <v-icon v-if="!Number(message.leido)">mdi-circle-small</v-icon>
    </v-card-subtitle>

    <v-divider />

    <div v-if="message.mensaje" class="px-4 py-2 msg--text" v-html="message.mensaje" />

    <div class="d-grid" v-if="message.children.length > 0">
      <template v-for="child in message.children" >
        <img
          v-if=" ['jpg', 'png', 'gif', 'jpeg', 'SVG', 'WebP'].includes( child.extension ) "
          :src="`https://renovables.alumbraenergia.es/msgs/${child.archivo}`"
          class="msg--img pa-1"
          v-viewer
        />
        <v-chip
          v-else
          label
          @click="descargarArchivo(child.archivo)"
          color="primary"
          outlined
          class="ma-1 mt-0"
        >
          <v-icon left>mdi-download</v-icon>
          {{ child.archivo.substring(child.archivo.indexOf("_") + 1) }}
        </v-chip>
      </template>
    </div>

    <div
      v-if="
        !own && message.leidoPorComercial && $root.acceso('EDITAR_ESTUDIOS')
      "
      class="float-right mt-n8 mr-3 "
    >
      <v-icon color="info" small>mdi-check-all</v-icon>
    </div>
        <ConfirmDialog ref="confirm" />
  </v-alert>
</template>

<script>
import { parseDate, downloadFile } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
  props: {
    message: {
      type: Object,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  components: {
    // StatusChip: () => import("@/components/StatusChip.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  computed: {
    own() {
      return (
        this.$store.getters.getTokenPayload.idUsuario == this.message.idUsuario
      );
    },
    comercial() {
      return this.message.idUsuario == this.message.idComercial;
    },
  },
  methods: {
    parseDate,
    descargarArchivo(name) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/mensajesEstudios.php`,
        responseType: "blob",
        params: {
          name,
        },
      }).then((res) => {
        let extension = name
          .split(".")
          [name.split(".").length - 1].toLowerCase();

        let file = new File([res.data], name.substring(name.indexOf("_") + 1), {
          type: extensions[extension],
        });

        let download = true;
        if (["pdf", "png", "jpg", "jpeg", "gif"].includes(extension))
          download = false;

        downloadFile(file, !download);
        this.downloadingFile = false;
        // window.open(fileURL);
      });
    },
    async eliminar() {
      if (! await this.$refs.confirm.open("Eliminar mensaje", "¿Seguro que quieres eliminar este mensaje?", { color: 'error', confirmColor: 'error',})) return;
      this.deleting = true;
      axios({
        method: "delete",
        url: `${process.env.VUE_APP_API_URL}/mensajesEstudios.php`,
        data: {
          idMensaje: this.message.idMensaje,
        },
      }).then((res) => {
        this.$emit("reload");
        this.deleting = false;
      });
    },
  },
};
</script>

<style lang="scss">
.message {
  align-self: start;
  min-width: 300px;
  max-width: calc(100% - 20px);
  .menu-btn {
    float: right;
  }
  margin-bottom: 3px;
  .v-alert__border {
    border-width: 1.25px;
  }
  .msg--img {
    display: inline-flex;
    width: 100%;
    max-width: 300px;
  }
}
.own {
  align-self: end;
  .menu-btn {
    float: left;
  }
  .msg--text {
    // text-align: right;
  }
}
// .v-alert__content {
//   display: flex;
//   flex-direction: column;
// }

.d-grid {
  display: grid;
  // grid-gap: 10px;
}
</style>

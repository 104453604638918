var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-alert',{class:{ message: true, own: _vm.own, 'pa-0': true, 'msg--caret': true },attrs:{"elevation":1,"colored-border":"","border":"bottom","color":_vm.message.userColor}},[_c('v-menu',{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-btn",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[(
              _vm.$root.acceso('DEV') ||
                (_vm.$root.acceso('EDITAR_ESTUDIOS') &&
                  _vm.own &&
                  new Date().getTime() <=
                    Number(_vm.message.fecha) + 1000 * 60 * 10)
            )?_c('v-list-item',{attrs:{"disabled":_vm.deleting},on:{"click":function($event){$event.stopPropagation();return _vm.eliminar.apply(null, arguments)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1),_vm._v(" Eliminar ")],1):_vm._e()],1)],1)],1)],1),_c('v-card-subtitle',{staticClass:"py-2"},[_c('span',{staticClass:"font-weight-bold mr-2",style:(("color: " + (_vm.message.userColor)))},[_vm._v(_vm._s(_vm.message.Nombre))]),(_vm.own)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-account")]):_vm._e(),(_vm.comercial)?_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-account-tie")]):_vm._e(),_vm._v(" "+_vm._s(new Date(Number(_vm.message.fecha)).toLocaleString())+" "),(!Number(_vm.message.leido))?_c('v-icon',[_vm._v("mdi-circle-small")]):_vm._e()],1),_c('v-divider'),(_vm.message.mensaje)?_c('div',{staticClass:"px-4 py-2 msg--text",domProps:{"innerHTML":_vm._s(_vm.message.mensaje)}}):_vm._e(),(_vm.message.children.length > 0)?_c('div',{staticClass:"d-grid"},[_vm._l((_vm.message.children),function(child){return [( ['jpg', 'png', 'gif', 'jpeg', 'SVG', 'WebP'].includes( child.extension ) )?_c('img',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"msg--img pa-1",attrs:{"src":("https://renovables.alumbraenergia.es/msgs/" + (child.archivo))}}):_c('v-chip',{staticClass:"ma-1 mt-0",attrs:{"label":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.descargarArchivo(child.archivo)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(child.archivo.substring(child.archivo.indexOf("_") + 1))+" ")],1)]})],2):_vm._e(),(
      !_vm.own && _vm.message.leidoPorComercial && _vm.$root.acceso('EDITAR_ESTUDIOS')
    )?_c('div',{staticClass:"float-right mt-n8 mr-3 "},[_c('v-icon',{attrs:{"color":"info","small":""}},[_vm._v("mdi-check-all")])],1):_vm._e(),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }